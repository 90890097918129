.sa-button-container {
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
}

.sweet-alert .sa-button-container .cancel,
.sweet-alert .sa-button-container .confirm {
    min-width: 100px;
}

.sweet-alert .sa-button-container .cancel {
    background-color: #2f4050;
    color: #ffffff;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 6px;
    padding-bottom: 8px;
    font-size: 12px;
    font-family: OpenSans-semibold;
    margin-left: 3px;
}

.sweet-alert .sa-button-container .cancel:hover {
    background-color: #41576d;
}

.sweet-alert .sa-button-container .confirm {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 6px;
    padding-bottom: 8px;
    font-size: 12px;
    font-family: OpenSans-semibold;
    margin-right: 0;
}

.sweet-alert .sa-button-container .confirm:hover {
    background-color: #42a6ee !important;
}

body.stop-scrolling {
    height: auto;
    overflow: auto;
}

.swal2-actions {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center; }

.swal2-container .swal2-actions .swal2-cancel {
    margin-left: 3px; }

.swal2-modal .swal2-checkbox input, .swal2-modal .swal2-checkbox span {
    margin: 0; }

.swal2-modal .swal2-checkbox span {
    margin-left: 5px; }

.swal2-container .sa-small h2 {
    font-size: 16px;
    line-height: 20px;
    margin: 5px 0; }

.swal2-container .sa-small .swal2-content,
.swal2-container .sa-small .swal2-html-container{
    font-size: 14px; }

.swal2-container .sa-small .swal2-actions {
    margin-top: 10px; }

.swal2-container .sa-small .swal2-checkbox {
    margin: 5px 0; }

body.swal2-shown {
    height: auto!important;
    overflow: auto!important;
    padding-right: 0!important; }

.sa-custom {
    -webkit-box-shadow: 0 5px 15px rgba(0,0,0,0.17);
    box-shadow: 0 5px 15px rgba(0,0,0,0.17);
}
body.swal2-toast-shown .swal2-container{
    width: auto;
}

.sa-permission-notifications .swal2-icon{
    display: none !important;
}

.swal2-html-container {
    margin: 0;
    padding: 1em 1.6em 0.3em;
}

body .swal2-container {
    z-index: 10060;
}