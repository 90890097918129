@import "../_settings.scss", "../functions/_util.scss";

.site-footer-top-level-wrap{
    position: relative;
    z-index: 10;
}

.site-footer-top-level-inner {
    padding-top: rem-calc(50px);
    padding-bottom: rem-calc(30px);
}

.footer-bottom-level-inner {
    padding-top: rem-calc(40px);
    padding-bottom: rem-calc(40px);
}

.footer-gifts-item {
    margin-top: rem-calc(30px);

    &:first-child {
        margin-top: rem-calc(53px);
    }
}
.footer-menu-link{
    vertical-align: middle;
}

.site-footer-mobile-app-links {
    padding-top: 30px;
}