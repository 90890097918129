.tooltip {  
  --bs-tooltip-bg: #000;
  --bs-tooltip-arrow-height: 0;
  --bs-tooltip-arrow-width: 0;
  --bs-tooltip-max-width: none;

  position: absolute;
  z-index: 1;
  display: block;
  opacity: 0;



  // Wrapper for the tooltip content
  &-inner {
    //max-width: @tooltip-max-width;
    padding: 5px 8px;
    color: white;
    text-align: center;
    background-color: #000;
    border-radius: 4px;
  }

  // Arrows
  &-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }

  &.right {
    margin-left: 3px;
    padding: 0 5px;
  }
  &.bottom {
    margin-top: 3px;
    padding: 5px 0;
  }
  &.left {
    margin-left: -3px;
    padding: 0 5px;
  }
  &-inner {
    //max-width: 200px;
    padding: 3px 8px;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 4px;
  }
  &-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }
  &.top &-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 5px 5px 0;
    border-top-color: #000;
  }
  &.top-left &-arrow {
    bottom: 0;
    right: 5px;
    margin-bottom: -5px;
    border-width: 5px 5px 0;
    border-top-color: #000;
  }
  &.top-right &-arrow {
    bottom: 0;
    left: 5px;
    margin-bottom: -5px;
    border-width: 5px 5px 0;
    border-top-color: #000;
  }
  &.right &-arrow {
    top: 50%;
    left: 0;
    margin-top: -5px;
    border-width: 5px 5px 5px 0;
    border-right-color: #000;
  }
  &.left &-arrow {
    top: 50%;
    right: 0;
    margin-top: -5px;
    border-width: 5px 0 5px 5px;
    border-left-color: #000;
  }
  &.bottom &-arrow {
    top: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: #000;
  }
  &.bottom-left &-arrow {
    top: 0;
    right: 5px;
    margin-top: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: #000;
  }
  &.bottom-right &-arrow {
    top: 0;
    left: 5px;
    margin-top: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: #000;
  }
  
  &.in     { 
    opacity: 1; 
  }
  &.top    { 
    margin-top: -3px; 
    padding: 5px 0; 
  }
  &.right  { 
    margin-left: 3px;
    padding: 0 5px; 
  }
  &.bottom { 
    margin-top: 3px; 
    padding: 5px 0; 
  }
  &.left { 
    margin-left: -3px; 
    padding: 0 5px; 
  }
}

