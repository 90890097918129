@import "../../../styles/variables.scss";

.carousel-ext,
.carousel-ext__track {
    display: block;
}

.carousel-ext__slide {
    display: block;
    width: 100vw;
    flex-shrink: 0;
    flex-grow: 1;
}

.carousel-ext__nav {
    &--bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 25px;
        text-align: center;
    }
}

.carousel-ext__arrow {
    /*color: $color-primary;*/
    border: none;
    box-shadow: none;
    text-shadow: none;
    border-radius: 0;

    &--prev {
        right: calc(100% + 2.2vw);
        transform: translateY(-50%) rotate(180deg);
    }

    &--next {
        left: calc(100% + 2.2vw);
    }

    &--hidden-xs {
        display: none;
    }

    &--bottom {
        background-color: transparent;
        cursor: pointer;
        margin-bottom: 0;

        &-prev {
            margin-top: 3px; // из-за transform:rotate(180deg) стрелки не на одной линии
            transform: rotate(180deg);
        }
    }
}

.carousel-ext__dot-item {
    background-color: #cfd6e6;

    &:hover,
    &:focus {
        border: 2px solid #cfd6e6;
    }

    &.glide__bullet--active {
        background-color: #112f91;

        &:hover,
        &:focus {
            border: 2px solid #112f91;
        }
    }
}

.carousel-ext__slides {
    will-change: auto; // не подгружалась первая картинка если lazy-load
}

.carousel-ext--full {
    .carousel-ext__arrow {
        &--prev {
            right: calc(100% - 50px);
            transform: translateY(-50%) rotate(180deg);
        }

        &--next {
            left: calc(100% - 50px);
        }

        &--bottom {
            background-color: transparent;
            cursor: pointer;
            margin-bottom: 0;

            &-prev {
                margin-top: 3px; // из-за transform:rotate(180deg) стрелки не на одной линии
                transform: rotate(180deg);
            }
        }
    }
}

.slider-disable {
    .glide__track {
        cursor:default;
    }
    .glide__arrow {
        display: none !important;
    }
}

@media (min-width: $viewport-sm) {
    .carousel-ext__arrow {
        &--visible-sm {
            display: block;
        }
    }
}

@media (min-width: $viewport-md) {
    .carousel-ext__arrow {
        &--visible-md {
            display: block;
        }
    }
}

@media (min-width: $viewport-lg) {
    .carousel-ext__arrow {
        &--visible-lg {
            display: block;
        }
    }
}

@media (min-width: $viewport-wl) {
    .carousel-ext__arrow {
        &--visible-xxl {
            display: block;
        }
    }
}
